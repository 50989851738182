import React from 'react';

const Education = () => (
    <div className="inner">
      <h2>Stationen</h2>
      <ul>
        <li>2013 - heute: Freie Mediatorin</li>
        <li>2010 - heute: Schulsozialarbeit in der Sekundarschule Wadersloh.</li>
        <li>2007 - 2024: Gruppendynamische Supervision Telefonseelsorge</li>
        <li>2003 - 2024: ehrenamtliche Telefonseelsorge in Hamm</li>
        <li>2009 - 2011: Selbständige Berufsbetreuerin</li>
        <li>1998 - 2009: Berufsbetreuung bei Ludger Heusener</li>
      </ul>
      <h2>Aus- und Fortbildungen</h2>
      <ul>
        <li>2011-2013: Ausbildung zur Mediatorin</li>
        <li>2009-2010: Fortbildung "Leiten von Gruppen"</li>
        <li>1984: Diplomstudiengang Soziale Arbeit an der KFH Aachen</li>
      </ul>
    </div>
);

export default Education;
