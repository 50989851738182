import React from 'react';

import ContactInformation from './ContactInformation';

const Contact = () =>
  <section id="contact" className="wrapper style1 fade-up">
    <div className="inner">
      <h2>So erreichen Sie mich</h2>
      <p>
      Haben Sie Interesse an einem Coaching oder einer Mediation? Dann schreiben Sie mir eine E-Mail.
      </p>
      <div className="split style1">
        <ContactInformation />
      </div>
    </div>
  </section>

export default Contact;
