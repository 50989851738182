import React from 'react';

import pic1 from '../../images/pic01.jpg';
import pic2 from '../../images/pic02.jpg';

import Feature from './Feature';

const Features = () => {
  const FEATURES = [
    {
      href: '/#',
      image: pic1,
      heading: 'Trennung und Scheidung',
      description: 'Unterstützung bei der Mediation von Trennungs- und Scheidungskonflikten, um faire und einvernehmliche Lösungen zu finden.',
      to: {
        href: '/mediation',
        label: 'Mehr erfahren',
      },
    },
    {
      href: '/#',
      image: pic2,
      heading: 'Familien',
      description: 'Mediation bei familiären Konflikten, um Beziehungen zu stärken und nachhaltige Lösungen zu entwickeln.',
      to: {
        href: '/coaching',
        label: 'Mehr erfahren',
      },
    },
  ];

  return (
    <section id="features" className="wrapper style2 spotlights">
      {FEATURES.map(feature => (
        <Feature key={feature.heading} {...feature} />
      ))}
    </section>
  );
}

export default Features;
