import React from 'react';
import Scroll from '../common/Scroll';

const Introduction = () =>
<section id="intro" className="wrapper style1 fullscreen fade-up">
    <div className="inner">
      <h1>Elisabeth Zensen - Mediatorin</h1>
      <blockquote>
        <p>Streitende sollen wissen, dass nie einer ganz recht hat und der andere ganz unrecht</p>
        <footer>Kurt Tucholsky</footer>
      </blockquote>
      <ul className="actions">
        <li>
          <Scroll type="id" element="one">
            <a href="#about" className="button">
              Mehr erfahren
            </a>
          </Scroll>
        </li>
      </ul>
    </div>
  </section>


export default Introduction;
