import React from 'react';
import profilePic from '../../images/profile.jpg'; // Stellen Sie sicher, dass Sie das Bild unter src/images/profile.jpg haben

const Biography = () => (
    <div className="inner">
      <h2>Über Mich</h2>
      <span className="image left"><img src={profilePic} alt="Elisabeth Zensen" /></span>
      <p>
        Mediation ist ein strukturiertes Verfahren zur Vermittlung und Regelung von Konflikten. 
        Mediation versachlicht den Konflikt und bietet jedem den Raum, seine Wünsche und Interessen darzulegen. 
        Ziel der Mediation ist eine gemeinsame und einvernehmliche Regelung der Konflikte durch die Konfliktparteien. 
        Streitigkeiten haben ihre eigene Dynamik und viele Faktoren spielen eine Rolle. 
        Aus diesem Grund ist die Lösung von Konflikten und deren Vermeidung nicht einfach. 
        Bei diesem Prozess werden Sie von mir als neutrale Dritte unterstützt. 
        Als Mediatorin biete ich Ihnen den Rahmen für Schutz, Respekt und Wertschätzung. 
        Die im Mediationsverfahren getroffene Vereinbarung ist rechtsverbindlich, 
        sofern das Gesetz nicht die notarielle Beurkundung vorschreibt. 
        In diesem Fall ist die Mediationsvereinbarung noch von Ihrem Notar zu beurkunden.
      </p>
    </div>
);

export default Biography;
