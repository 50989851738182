import React from "react";

const ContactInformation = () =>
  <section>
    <ul className="contact">
      <li>
        <h3>Addresse</h3>
        <span>
          Krügerstraße 23
          <br />
          59269 Beckum
        </span>
      </li>
      <li>
        <h3>Email</h3>
        <span>elisabeth.zensen@gmail.com</span>
      </li>
    </ul>
  </section>

export default ContactInformation;
