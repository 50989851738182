import React from 'react';

import Biography from './Biography';
import Education from './Education';

const About = () => (
  <section id="about" className="wrapper style1 fade-up">
    <Biography />
    <Education />
  </section>
);

export default About;
